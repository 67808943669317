import * as React from "react"
import Footer from "src/components/footer"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import ServiceHeader from 'src/components/service-header'
import Wysiwyg from "src/components/wysiwyg"
import footerData from "src/data/global/footer"

const meta = {
  title: "Impressum",
  description: "Du möchtest noch mehr von mir wissen? Anschrift, UID-Nummer oder so? Dann bist du hier richtig! Willkommen im Impressum!"
}

const atf = {
  heading: "<strong>Impressum</strong>",
  hasBackground: true,
  image: "services-default",
  alt: "icon of html tag",
}

const content = {
  hasBackground: false,
  heading: "<strong>Webentwicklung Dominik Schratl</strong>",
  content: [
    {
      type: "text",
      text: `
          <p>
            <strong>Adresse:</strong></br>
            Mag. Dominik Schratl</br>
            Müllerstraße 2 / 104</br>
            6020 Innsbruck, Österreich</br>
          </p>
          <p>
            <strong>Tel:</strong> +43 677 640 875 33</br>
            <strong>E-Mail:</strong> hi@dominik-schratl.at
          </p>
          <p>
            <strong>Unternehmensgegenstand:</strong> Konzeption, Entwicklung und Betrieb von Webseiten, Webapps und Zusatzsoftware
          </p>
          <p>
            <strong>Gewerbewortlaut:</strong> Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik
          </p>
          <p>
            <strong>Gewerbeordnung:</strong> www.ris.bka.gv.at
          </p>
          <p>
            <strong>Steuernummer:</strong> ATU76138207
          </p>
          <p>
            <strong>Mitglied der WKÖ</strong>
          </p>
          <p>
            <strong>Bezirkshauptmannschaft Innsbruck</strong>
          </p>
          <p>
            Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
          </p>
          <h3><strong>Medien:</strong></h3>
          <p>
            Icons und Illustrationen von Naomi Neururer
          </p>
          <h2><strong>Haftungsausschluss:</strong></h2>
          <h3><strong>Inhalt des Onlineangebotes:</strong></h3>
          <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Richtigkeit und Vollständigkeit der bereitgestellten Informationen auf unserer Website. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.</p>
          <p>Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
          <h3><strong>Verweise und Links:</strong></h3>
          <p>Bei direkten oder indirekten Verweisen auf fremde Webseiten (“Hyperlinks”), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.</p>
          <p>Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden.</p>
          <p>Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
          <h3><strong>Urheber- und Kennzeichenrecht:</strong></h3>
          <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.</p>
          <p>Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!</p>
          <p>Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>
          <p>
            Quelle: <a href="http://www.haftungsausschluss.org/datenschutzerklaerung-muster-generator-dsgvo/">Datenschutzerklärung Muster</a> von <a href="http://www.haftungsausschluss-vorlage.de/haftungsausschluss/">Haftungsausschluss-Vorlage.de</a> und <a href="http://www.datenschutzgesetz.de/">Datenschutzgesetz.de</a>
          </p>
      `,
    }
  ]
}

const Imprint = () => (
  <Layout>
    <Seo title={meta.title} description={meta.description} url="/impressum" />
    <ServiceHeader data={atf} />
    <Wysiwyg data={content} />
    <Footer data={footerData} hasBackground />
  </Layout>
)

export default Imprint
